import { gql } from 'GQL';

export const TaskFragment = gql(`
  fragment TaskFragment on Task {
    id
    barcode
    count
    createdAt
    updatedAt
    status
  }
`);
