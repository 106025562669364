import { ArrowDownIcon, ArrowUpIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './inventory.module.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
  DeleteInventoryMutation,
  GetInventoriesQuery,
  SearchInventoriesQuery,
  UpdateInventoryMutation,
  UpsertInventoryMutation,
} from 'GraphqlQueries';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'Components/Loader';
import { GQLInventory } from 'Types';
import ToastPrompt from 'Components/ToastPrompt';
import InventoryRow from './inventoryRow';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import { AppContext } from 'Components/AppShell/context';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Inventory() {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'updatedAt' | 'createdAt' | 'barcode'>('updatedAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [isRealTime, setIsRealTime] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [scanadd, setScanAdd] = useState<boolean>(true);

  console.log(search);

  const { data, loading, error, fetchMore, startPolling, stopPolling } = useQuery(
    search !== '' ? SearchInventoriesQuery : GetInventoriesQuery,
    {
      variables: {
        input: {
          page: 1,
          per_page: 50,
          semantic: search !== '' ? false : undefined,
          query_by: search !== '' ? ['barcode'] : undefined,
          sort_by: sortBy,
          sort_dir: sortDirection,
          q: search !== '' ? search : undefined,
        },
      },
    },
  );

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page: 50,
            semantic: search !== '' ? false : undefined,
            query_by: search !== '' ? ['barcode'] : undefined,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (fetchMoreResult.inventories.length === 0 || fetchMoreResult.inventories.length < 50) {
            setHasMore(false);
          }
          return {
            ...prev,
            inventories: [...prev.inventories, ...fetchMoreResult.inventories],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, search, sortBy, sortDirection],
  );

  const [upsertInventory] = useMutation(UpsertInventoryMutation);
  const [deleteInventory] = useMutation(DeleteInventoryMutation);
  const [updateInventory] = useMutation(UpdateInventoryMutation);
  (window as any)['upsertInventory'] = upsertInventory;

  const { ports, requestUserAction, useSerialData } = useContext(AppContext);
  const [scannerData, setScannerData] = useSerialData();

  const deleteRowPrompt = (barcode: string) => {
    const toastId = toast(
      <ToastPrompt
        onAbort={() => {
          toast.dismiss(toastId);
        }}
        onApply={() => {
          deleteInventory({
            variables: {
              id: parseInt(barcode),
            },
          });
          toast.dismiss(toastId);
        }}
        actionLabel="Elimina"
        textLabel={`Sicuro di voler eliminare definitivamente l'elemento?`}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  useEffect(() => {
    if (scannerData.data !== '') {
      if (scanadd) {
        upsertInventory({
          variables: {
            input: {
              barcode: scannerData.data,
            },
          },
        });
      } else {
        setSearch(scannerData.data);
        setScannerData({ data: '' });
      }
    }
  }, [scanadd, scannerData, setScannerData, upsertInventory]);

  useEffect(() => {
    if (isRealTime) {
      startPolling(1000);
    } else {
      stopPolling();
      setPage(1);
    }
  }, [isRealTime, startPolling, stopPolling]);

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.inventories.length || 0}
        next={() => {
          stopPolling();
          setIsRealTime(false);
          nextPage(page + 1);
        }}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        <ContentHeader
          title="Inventario"
          isSearchVisible={true}
          onClickSearch={(s: string) => setSearch(s)}
          onDebounceSearch={(s: string) => setSearch(s)}
          searchText={search}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'barcode'}
              onClick={() => {
                setSortBy('barcode');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Barcode
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'updatedAt'}
              onClick={() => {
                setSortBy('updatedAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Modifica
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup label="Scan & add">
            <ContentHeaderButton selected={scanadd} onClick={() => setScanAdd(true)}>
              Scan & add
            </ContentHeaderButton>
            <ContentHeaderButton selected={!scanadd} onClick={() => setScanAdd(false)}>
              Cerca
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup label="Tempo reale">
            <ContentHeaderButton selected={isRealTime} onClick={() => setIsRealTime(true)}>
              Tempo Reale
            </ContentHeaderButton>
            <ContentHeaderButton selected={!isRealTime} onClick={() => setIsRealTime(false)}>
              Stop
            </ContentHeaderButton>
          </ContentHeaderGroup>
          <ContentHeaderGroup label="Barcode Scanner">
            <ContentHeaderButton selected={ports && ports.length > 0} onClick={() => requestUserAction()}>
              {ports && ports.length > 0 ? 'Connesso' : 'Connetti'}
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>
        <div className={style.contentBody}>
          {loading && <Loader />}
          <div className={style.inventoryHeader}>
            <IconSpec
              icon={<CloudArrowUpIcon width={20} color={style.green} />}
              label="Applica inventario"
              labelStyle={{ color: style.green }}
              onClick={() => {
                console.log('applica inventario');
              }}
            />
            <IconSpec
              icon={<CloudArrowUpIcon width={20} color={style.red} />}
              label="Cancella tutto"
              labelStyle={{ color: style.red }}
              onClick={() => {
                console.log('cancella tutto');
              }}
            />
          </div>
          <div className={style.inventory}>
            {data?.inventories.map((inventory: GQLInventory, i: number) => (
              <InventoryRow
                key={i}
                inventory={inventory}
                deleteRowPrompt={() => deleteRowPrompt(inventory.id)}
                deleteRow={() => console.log('deleteRow')}
                editCount={(count) => {
                  updateInventory({
                    variables: {
                      id: parseInt(inventory.id),
                      input: {
                        count: inventory.count + count,
                      },
                    },
                  });
                }}
              />
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
}
