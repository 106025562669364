import { ContentHeader, ContentHeaderButton, ContentHeaderGroup } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GQLTask } from 'Types';
import { GetTasksQuery, SearchTasksQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';

import style from './tasks.module.scss';
import Task from './task';
import { toast } from 'react-toastify';
import { AppContext } from 'Components/AppShell/context';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from 'GQL/graphql';

export default function Tasks(): JSX.Element {
  const { useSerialData } = useContext(AppContext);
  const [scannerData] = useSerialData();
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = useState<'barcode' | 'id' | 'createdAt' | 'updatedAt'>('createdAt');
  const [search, setSearch] = useState<string>(scannerData.data === '' ? '.*' : scannerData.data);
  const [statusFilter, setStatusFilter] = useState<TaskStatus | 'all'>('all');
  const isSearch = search !== '' || statusFilter !== 'all' || scannerData.data !== '';

  const { data, loading, error, fetchMore, previousData } = useQuery(isSearch ? SearchTasksQuery : GetTasksQuery, {
    variables: {
      input: {
        q: isSearch ? search : undefined,
        query_by: isSearch ? ['barcode'] : undefined,
        page: 1,
        per_page: 10,
        sort_by: sortBy,
        sort_dir: sortDirection,
        filter_by: statusFilter !== 'all' ? `status:${statusFilter.toLocaleLowerCase()}` : undefined,
      },
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    setSearch(scannerData.data);
  }, [scannerData.data]);

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei lavori: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            q: isSearch ? search : undefined,
            query_by: isSearch ? ['barcode'] : undefined,
            page,
            per_page: 10,
            sort_by: sortBy,
            sort_dir: sortDirection,
            filter_by: statusFilter !== 'all' ? `status:${statusFilter.toLocaleLowerCase()}` : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            tasks: [...prev.tasks, ...fetchMoreResult.tasks],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, isSearch, search, sortBy, sortDirection, statusFilter],
  );

  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.tasks.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={(previousData?.tasks.length || 0) < (data?.tasks.length || 0)}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title="Lavori"
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'barcode'}
              onClick={() => {
                setSortBy('barcode');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Barcode
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'id'}
              onClick={() => {
                setSortBy('id');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              ID
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'updatedAt'}
              onClick={() => {
                setSortBy('updatedAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data Modifica
            </ContentHeaderButton>
          </ContentHeaderGroup>

          <ContentHeaderGroup label="Filtri rapidi:">
            <ContentHeaderButton selected={statusFilter === 'all'} onClick={() => setStatusFilter('all')}>
              Tutti
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={statusFilter === TaskStatus.Cancelled}
              onClick={() => setStatusFilter(TaskStatus.Cancelled)}
            >
              Cancellati
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={statusFilter === TaskStatus.InProgress}
              onClick={() => setStatusFilter(TaskStatus.InProgress)}
            >
              In Corso
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={statusFilter === TaskStatus.UserWaiting}
              onClick={() => setStatusFilter(TaskStatus.UserWaiting)}
            >
              In Attesa Utente
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={statusFilter === TaskStatus.Pending}
              onClick={() => setStatusFilter(TaskStatus.Pending)}
            >
              In Coda
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={statusFilter === TaskStatus.Completed}
              onClick={() => setStatusFilter(TaskStatus.Completed)}
            >
              Completati
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.tasks.map((task: GQLTask) => (
            <Task key={task.id} task={task} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
