import style from './toastprompt.module.scss';

export default function ToastPrompt({
  onApply,
  onAbort,
  actionLabel,
  textLabel,
}: {
  onApply: () => void;
  onAbort: () => void;
  actionLabel: string;
  textLabel: string;
}): JSX.Element {
  return (
    <div className={style.toast}>
      <div className={style.toastTitle}>
        <span className={style.toastTitleText}>{textLabel}</span>
      </div>
      <div className={style.toastContent}>
        <button className={style.toastContentDelete} onClick={onApply}>
          {actionLabel}
        </button>
        <button className={style.toastContentAbort} onClick={onAbort}>
          Annulla
        </button>
      </div>
    </div>
  );
}
