import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import style from './index.module.scss';

import { AppContext } from 'Components/AppShell/context';
import { useContext } from 'react';

import Scan from './scan';

export default function Scanner() {
  const { ports, requestUserAction, useSerialData } = useContext(AppContext);

  return (
    <div className={style.content}>
      <ContentHeader title="Nuova scansione" isSearchVisible={false}>
        <ContentHeaderGroup label="Barcode Scanner">
          <ContentHeaderButton selected={ports && ports.length > 0} onClick={() => requestUserAction()}>
            {ports && ports.length > 0 ? 'Connesso' : 'Connetti'}
          </ContentHeaderButton>
        </ContentHeaderGroup>
      </ContentHeader>
      <div className={style.contentBody}>
        <Scan useSerialData={useSerialData} />
      </div>
    </div>
  );
}
