import SearchBar from 'Components/SearchBar';
import style from './contentheader.module.scss';
import { Tag, Tags } from 'Components/Tag';

export function ContentHeader({
  title,
  children,
  isSearchVisible,
  searchText: search,
  onClickSearch,
  onDebounceSearch,
  filters,
  onFilterRemove,
}: {
  title: string;
  children?: React.ReactNode[] | React.ReactNode;
  isSearchVisible?: boolean;
  searchText?: string;
  onClickSearch?: (search: string) => void;
  onDebounceSearch?: (search: string) => void;
  filters?: { label: string; value: string }[];
  onFilterRemove?: (value: string) => void;
}) {
  return (
    <div className={style.contentHeader}>
      {isSearchVisible && <SearchBar searchText={search} onDebounce={onDebounceSearch} onClick={onClickSearch} />}
      <Tags addPlusIcon={false} emtyMessage={''}>
        {filters &&
          filters.map((f, i) => (
            <Tag key={i} onClick={() => onFilterRemove && onFilterRemove(f.value)}>
              <>
                {f.label}: {f.value}
              </>
            </Tag>
          ))}
      </Tags>
      <div className={style.contentTitle}>
        <div className={style.contentTitleText}>{title}</div>
        <div className={style.contentTitleActions}>{children}</div>
      </div>
    </div>
  );
}

export function ContentHeaderGroup({ children, label }: { children: React.ReactNode; label?: string }) {
  return (
    <div className={style.contentHeaderGroup}>
      {label && <div className={style.contentHeaderGroupLabel}>{label}</div>}
      <div className={style.contentHeaderGroupButtons}>{children}</div>
    </div>
  );
}

export function ContentHeaderButton({
  children,
  selected,
  icon,
  onClick,
}: {
  children: React.ReactNode;
  selected?: boolean;
  onClick: () => void;
  icon?: JSX.Element;
}) {
  return (
    <div className={style.contentHeaderButton} onClick={onClick}>
      <div className={`${style.contentHeaderButtonText} ${selected ? style.contentHeaderButtonTextSelected : ''}`}>
        {children} {selected ? icon : null}
      </div>
    </div>
  );
}
