import { gql } from 'GQL';

export const GetCategoriesQuery = gql(`
  query GetCategories($input: GraphQLListInput!) {
    categories: categories(input: $input) {
      ...CategoryFragment
    }
  }
`);

export const SearchCategoriesQuery = gql(`
  query SearchCategories($input: GraphQLSearchInput!) {
    categories: searchCategories(input: $input) {
      ...CategoryFragment
    }
  }
`);
