import { gql } from 'GQL';

export const AttributeFragment = gql(`
  fragment AttributeFragment on Attribute {
    id
    createdAt
    updatedAt
    synchedAt
    slug
    name
    type
    originalId
  }
`);
