import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { AuthCallback } from 'casdoor-react-sdk';
import * as Setting from 'utils/casdoor';
import { WareHouseContent, WareHouseSidebar } from 'Components/WareHouse';
import Protected from 'Components/Protected';
import AppShell from 'Components/AppShell';
import { ViewModule } from 'Types';
import { SettingsContent, SettingsSidebar } from 'Components/Settings';

function App() {
  const authCallback = (
    <AuthCallback
      sdk={Setting.CasdoorSDK}
      serverUrl={Setting.ServerUrl}
      saveTokenFromResponse={(res: any) => {
        Setting.setToken(res.data.access_token);
        Setting.setRefreshToken(res.data.refresh_token);
        window.location.href = '/';
      }}
      isGetTokenSuccessful={(res: any) => {
        return res.status === 'ok';
      }}
    />
  );

  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Routes>
        <Route path="/callback" element={authCallback} />
        <Route
          path="/"
          element={
            <Protected>
              <Navigate to="/warehouse/products" />
            </Protected>
          }
        />
        <Route
          path="/warehouse/*"
          element={
            <Protected>
              <AppShell module={ViewModule.WareHouse} sidebar={<WareHouseSidebar />} content={<WareHouseContent />} />
            </Protected>
          }
        />
        <Route
          path="/settings/*"
          element={
            <Protected>
              <AppShell module={ViewModule.Settings} sidebar={<SettingsSidebar />} content={<SettingsContent />} />
            </Protected>
          }
        />
        <Route
          path="*"
          element={
            <Protected>
              <Navigate to="/warehouse/products" />
            </Protected>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
