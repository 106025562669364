import { useState, useEffect, useCallback, useMemo } from 'react';

import { serial as polyfill, SerialPort as SerialPortPolyfill } from 'web-serial-polyfill';

export type TSerialPort = SerialPortPolyfill | SerialPort;

if (!('serial' in navigator)) {
  if ('usb' in navigator) {
    (navigator as any).serial = polyfill;
  } else {
    console.error('Web Serial API is not supported');

    (navigator as any).serial = {
      requestPort: async () => {
        throw new Error('Web Serial API is not supported');
      },
      getPorts: async () => {
        throw new Error('Web Serial API is not supported');
      },
    };
  }
}

async function portReader(reader: ReadableStreamDefaultReader<Uint8Array>, emitter: EventTarget) {
  const textDecoder = new TextDecoder();
  try {
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }
      emitter.dispatchEvent(new CustomEvent('data', { detail: textDecoder.decode(value) }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    reader.releaseLock();
  }
}

export function useSerialPort() {
  const [ports, setPorts] = useState<TSerialPort[]>([]);
  const emitter = useMemo(() => new EventTarget(), []);

  const requestUserAction = async () => {
    try {
      const device = await navigator.serial.requestPort({ filters: [] });
      setPorts([...ports, device].filter((port, index, array) => array.indexOf(port) === index));
    } catch (error) {
      console.error(error);
    }
  };

  const openPorts = useCallback(
    (ports: TSerialPort[]) => {
      const readers: ReadableStreamDefaultReader<Uint8Array>[] = [];

      const openAndRead = async (port: TSerialPort) => {
        if (!port.readable) {
          return;
        }

        const reader = port.readable.getReader();
        readers.push(reader);
        portReader(reader, emitter);
      };

      for (const port of ports) {
        port
          .open({ baudRate: 9600 })
          .then(() => openAndRead(port))
          .catch(() => openAndRead(port))
          .catch(console.error);
      }

      return () => {
        for (const reader of readers) {
          reader.cancel().catch(console.error);
        }
      };
    },
    [emitter],
  );

  useEffect(() => {
    navigator.serial.getPorts().then(async (ports) => {
      setPorts(ports);
    });
  }, []);

  useEffect(() => {
    return openPorts(ports);
  }, [openPorts, ports]);

  const useSerialData = (): [{ data: string }, React.Dispatch<React.SetStateAction<{ data: string }>>] => {
    const [serialData, setSerialData] = useState<{ data: string }>({ data: '' });

    const callback = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      setSerialData({ data: customEvent.detail });
    };

    useEffect(() => {
      emitter.addEventListener('data', callback);
      return () => {
        emitter.removeEventListener('data', callback);
      };
    }, []);

    return [serialData, setSerialData];
  };

  return { requestUserAction, ports, useSerialData };
}
