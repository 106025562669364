import Sdk from 'casdoor-js-sdk';

function isString(value: any): value is string {
  return typeof value === 'string';
}

export const ServerUrl = `${process.env.NODE_ENV === 'development' ? 'http://localhost:8002' : ''}`;

export const sdkConfig = {
  serverUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://auth.manager.etruria.fishing',
  //clientId: 'a3e6e9e11d817075d569',
  clientId: isString(process.env.REACT_APP_CASDOOR_CLIENT_ID) ? process.env.REACT_APP_CASDOOR_CLIENT_ID : '',
  appName: 'etruria_manager',
  organizationName: 'etruria_fishing',
  redirectPath: '/callback',
  signinPath: '/api/v1/auth/callback',
};

export const CasdoorSDK = new Sdk(sdkConfig);

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refreshToken', refreshToken);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

export const clearToken = () => {
  localStorage.removeItem('token');
};

export const clearRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

export const isTokenValid = () => {
  return localStorage.getItem('token') !== null;
};

export const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  return token !== null && token !== '';
};
