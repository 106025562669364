import { gql } from 'GQL';

export const GetProductVariationsQuery = gql(`
  query GetProductVariations($input: GraphQLListInput!) {
    productsVariations: productVariations(input: $input) {
      ...ProductVariationFragmentWithParent
    }
  }
`);

export const SearchProductVariationsQuery = gql(`
  query SearchProductVariations($input: GraphQLSearchInput!) {
    productVariations: searchProductVariations(input: $input) {
      ...ProductVariationFragmentWithParent
    }
  }
`);
