import { QrCodeIcon } from '@heroicons/react/24/outline';
import { Sidebar, SidebarMenu, SidebarMenuItem } from 'Components/Sidebar';

export function SettingsSidebar(): JSX.Element {
  return (
    <Sidebar>
      <SidebarMenu>
        <SidebarMenuItem icon={<QrCodeIcon width={20} />} to="/settings/scanner">
          Scanner
        </SidebarMenuItem>
      </SidebarMenu>
    </Sidebar>
  );
}
