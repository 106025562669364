import { XCircleIcon } from '@heroicons/react/24/solid';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import style from './tag.module.scss';

export function Tags({
  children,
  addPlusIcon = true,
  emtyMessage = 'Nessun elemento',
}: {
  children?: JSX.Element | JSX.Element[];
  addPlusIcon?: boolean;
  emtyMessage?: string;
}) {
  const noChildren = Array.isArray(children) ? children.length === 0 : !children;
  return (
    <div className={style.tags}>
      {!noChildren ? children : emtyMessage && <span className={style.tagsEmpty}>{emtyMessage}</span>}
      {addPlusIcon && <PlusCircleIcon width={20} />}
    </div>
  );
}

export function Tag({ children, onClick }: { children: JSX.Element | string; onClick?: () => void }) {
  return (
    <div className={style.tag}>
      <XCircleIcon width={14} onClick={onClick} /> {children}
    </div>
  );
}
