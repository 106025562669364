import style from './scan.module.scss';
import { QrCodeIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  CreateTaskMutation,
  SearchProductsQuery,
  UpdateProductMutation,
  SearchProductVariationsQuery,
  UpdateProductVariationMutation,
} from 'GraphqlQueries';

export default function Scan({
  useSerialData,
}: {
  useSerialData: () => readonly [{ data: string }, React.Dispatch<React.SetStateAction<{ data: string }>>];
}) {
  const [data] = useSerialData();
  const [barcode, setBarcode] = useState<string>(data.data);
  const [text, setText] = useState<string>('');
  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const [createTask] = useMutation(CreateTaskMutation, {
    variables: {
      barcode,
    },
  });

  const [updateProduct] = useMutation(UpdateProductMutation);
  const [updateProductVariation] = useMutation(UpdateProductVariationMutation);

  // ricerca codice a barre
  const [loadProducts] = useLazyQuery(SearchProductsQuery, {
    variables: {
      input: {
        page: 1,
        per_page: 1,
        q: '*',
        filter_by: `sku:${barcode}`,
      },
    },
  });

  const [loadVariationProducts] = useLazyQuery(SearchProductVariationsQuery, {
    variables: {
      input: {
        page: 1,
        per_page: 1,
        q: '*',
        filter_by: `sku:${barcode}`,
      },
    },
  });

  useEffect(() => {
    setBarcode(data.data);
  }, [data]);

  useEffect(() => {
    async function checkAndUpdate() {
      const data = await loadProducts();
      if (data.data && data.data.products.length > 0) {
        await updateProduct({
          variables: {
            id: parseFloat(data.data.products[0].id),
            input: {
              stockCount: data.data.products[0].stockCount + 1,
            },
          },
        });
        toast.success(
          <>
            <b>{data.data.products[0].title}</b> aggiornato con successo <br />
            stockCount: {data.data.products[0].stockCount + 1}
          </>,
        );
        return;
      }

      const dataVariation = await loadVariationProducts();

      if (dataVariation.data && dataVariation.data.productVariations.length > 0) {
        await updateProductVariation({
          variables: {
            id: parseFloat(dataVariation.data.productVariations[0].id),
            input: {
              stockCount: dataVariation.data.productVariations[0].stockCount + 1,
            },
          },
        });
        toast.success(
          <>
            <b>{dataVariation.data.productVariations[0].parent?.title}</b> aggiornato con successo <br />
            stockCount: {dataVariation.data.productVariations[0].stockCount + 1}
          </>,
        );
        return;
      }

      const task = await createTask();
      if (task.data && task.data.task) {
        toast.success(
          <>
            Task creato con successo <br />
            <b>Barcode:</b> {barcode}
          </>,
        );
      } else {
        toast.error('Errore durante la creazione del task');
      }
    }

    if (barcode !== '') {
      checkAndUpdate().then(() => {
        setBarcode('');
      });
    }
  }, [barcode, loadProducts, loadVariationProducts, updateProduct, updateProductVariation, createTask]);

  return (
    <div className={style.contentBodyScanner}>
      <div className={style.contentBodyScannerImage}>
        <QrCodeIcon width={180} />
      </div>
      <div className={style.contentBodyScannerText}>In attesa di una scansione</div>
      <div className={style.contentBodyScannerUnderText} onClick={() => setInputVisible(!inputVisible)}>
        Inserisci manualmente
      </div>
      {inputVisible && (
        <div className={style.contentBodyScannerInput}>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Inserisci il codice a barre"
            className={style.contentBodyScannerInputField}
          />
          <button className={style.contentBodyScannerInputButton} onClick={() => setBarcode(text)}>
            Conferma
          </button>
        </div>
      )}
    </div>
  );
}
