import { GQLAttribute } from 'Types';
import style from './attribute.module.scss';
import { DocumentDuplicateIcon, ArrowPathIcon, TrashIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Attribute({ attribute }: { attribute: GQLAttribute }) {
  const navigate = useNavigate();
  const [syncStatus, setSyncStatus] = useState<string>('Sincronizzato');

  useEffect(() => {
    if (attribute.originalId) {
      if (!attribute.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else if (attribute.createdAt > attribute.synchedAt) {
        setSyncStatus('Non Sincronizzato');
      } else if (attribute.updatedAt > attribute.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else {
        setSyncStatus('Sincronizzato');
      }
    } else {
      setSyncStatus('Non sincronizzato');
    }
  }, [attribute]);

  return (
    <div className={style.attribute}>
      <IconSpec icon={<DocumentDuplicateIcon width={20} />} label="Nome" content={attribute.name} />
      <IconSpec
        icon={<ArrowPathIcon width={20} />}
        label="Stato sincronizzazione"
        content={syncStatus}
        contentStyle={{ color: syncStatus === 'Sincronizzato' ? style.green : style.red }}
      />
      <div className={style.attributeButtons}>
        <IconSpec
          icon={<RectangleGroupIcon width={20} color={style.green} />}
          label="Visualizza"
          labelStyle={{ color: style.green }}
          onClick={() => navigate(`/warehouse/attributeValues/${attribute.id}/${attribute.name}`)}
        />
        <IconSpec
          icon={<TrashIcon width={20} color={style.red} />}
          label="Elimina"
          labelStyle={{ color: style.red }}
          onClick={() => console.log('delete')}
        />
      </div>
    </div>
  );
}
