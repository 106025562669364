import { GQLTag } from "Types";
import style from './tag.module.scss';
import { DocumentDuplicateIcon, ArrowPathIcon, TrashIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";
import { IconSpec } from "Components/IconSpec";
import { useEffect, useState } from "react";

export default function Tag({ tag }: { tag: GQLTag }) {
    const [syncStatus, setSyncStatus] = useState<string>('Sincronizzato');

    useEffect(() => {
        if (tag.originalId) {
            if (!tag.synchedAt) {
                setSyncStatus('Non sincronizzato');
            } else if (tag.createdAt > tag.synchedAt) {
                setSyncStatus('Non Sincronizzato');
            } else if (tag.updatedAt > tag.synchedAt) {
                setSyncStatus('Non sincronizzato');
            } else {
                setSyncStatus('Sincronizzato');
            }
        } else {
            setSyncStatus('Non sincronizzato');
        }
    }, [tag]);

    return <div className={style.attribute}>
        <IconSpec
            icon={<DocumentDuplicateIcon width={20} />}
            label="Nome"
            content={tag.name}
        />
        <IconSpec
            icon={<ArrowPathIcon width={20} />}
            label="Stato sincronizzazione"
            content={syncStatus}
            contentStyle={{ color: syncStatus === 'Sincronizzato' ? style.green : style.red }}
        />
        <div className={style.attributeButtons}>
            <IconSpec
                icon={<RectangleGroupIcon width={20} color={style.green} />}
                label="Visualizza"
                labelStyle={{ color: style.green }}
                onClick={() => console.log('view')}
            />
            <IconSpec
                icon={<TrashIcon width={20} color={style.red} />}
                label="Elimina"
                labelStyle={{ color: style.red }}
                onClick={() => console.log('delete')}
            />
        </div>
    </div>
}