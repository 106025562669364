import { gql } from 'GQL';

export const TaskResultFragment = gql(`
  fragment TaskResultFragment on TaskResult {
    module
    data {
      name
      category
      variants {
        attributes
        sku
        price
        images
        barcode
        quantity
      }
      sku
      description
      brand
    }
  }
`);
