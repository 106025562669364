import { gql } from 'GQL';

export const GetTasksQuery = gql(`
  query GetTasksQuery($input: GraphQLListInput!) {
    tasks: tasks(input: $input) {
      ...TaskFragment
    }
  }
`);

export const SearchTasksQuery = gql(`
  query SearchTasks($input: GraphQLSearchInput!) {
    tasks: searchTasks(input: $input) {
      ...TaskFragment
    }
  }
`);
