import { gql } from 'GQL';

export const GetAttributeValuesQuery = gql(`
  query GetAttributeValues($input: GraphQLListInput!) {
    attributeValues: attributeValues(input: $input) {
      ...AttributeValueFragment
    }
  }
`);

export const SearchAttributeValuesQuery = gql(`
  query SearchAttributeValues($input: GraphQLSearchInput!) {
    attributeValues: searchAttributeValues(input: $input) {
      ...AttributeValueFragment
    }
  }
`);
