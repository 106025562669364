import { GQLCategory } from 'Types';
import style from './category.module.scss';
import { DocumentDuplicateIcon, ArrowPathIcon, TrashIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Category({ category }: { category: GQLCategory }) {
  const navigate = useNavigate();
  const [syncStatus, setSyncStatus] = useState<string>('Sincronizzato');

  useEffect(() => {
    if (category.originalId) {
      if (!category.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else if (category.createdAt > category.synchedAt) {
        setSyncStatus('Non Sincronizzato');
      } else if (category.updatedAt > category.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else {
        setSyncStatus('Sincronizzato');
      }
    } else {
      setSyncStatus('Non sincronizzato');
    }
  }, [category]);

  return (
    <div className={style.attribute}>
      <IconSpec icon={<DocumentDuplicateIcon width={20} />} label="Nome" content={category.name} />
      <IconSpec
        icon={<ArrowPathIcon width={20} />}
        label="Stato sincronizzazione"
        content={syncStatus}
        contentStyle={{ color: syncStatus === 'Sincronizzato' ? style.green : style.red }}
      />
      <div className={style.attributeButtons}>
        <IconSpec
          icon={<RectangleGroupIcon width={20} color={style.green} />}
          label="Visualizza"
          labelStyle={{ color: style.green }}
          onClick={() => navigate(`/warehouse/categories/${category.id}/${category.name}`)}
        />
        <IconSpec
          icon={<TrashIcon width={20} color={style.red} />}
          label="Elimina"
          labelStyle={{ color: style.red }}
          onClick={() => console.log('delete')}
        />
      </div>
    </div>
  );
}
