import { useState } from 'react';
import { GQLProductVariation } from 'Types';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import style from './variationsswatch.module.scss';

function SwatchItem({
  defaultImage,
  title,
  variation,
  onClick,
  selected,
}: {
  defaultImage: string;
  title?: string;
  variation: GQLProductVariation | undefined;
  onClick?: () => void;
  selected?: boolean;
}): JSX.Element {
  return (
    <div className={`${style.swatchItem} ${selected ? style.swatchItemSelected : ''}`} onClick={onClick}>
      <div className={style.swatchItemImage}>
        <img src={variation?.imageURLs[0] || defaultImage} alt={variation?.title} />
      </div>
      <div className={style.swatchItemInfo}>
        <div className={style.swatchItemInfoTitle}>{variation ? title : 'Seleziona variante'}</div>
        <div className={style.swatchItemInfoAttributes}>
          {variation?.attributeValues.map((a, i) => (
            <span key={i} className={style.swatchItemInfoAttribute}>
              <b>{a.attribute.name}</b>: {a.value}
            </span>
          ))}
        </div>
      </div>
      {variation && (
        <div className={style.swatchItemSpec}>
          <div className={style.swatchItemSpecPrice}>{variation?.price} €</div>
          <div
            className={style.swatchItemSpecStock}
            style={{ color: variation.stockCount > 0 ? style.green : style.red }}
          >
            {variation.stockCount > 0 ? `${variation.stockCount} disponibile/i` : 'Non disponibile'}
          </div>
        </div>
      )}
    </div>
  );
}

export default function VariationsSwatch({
  defaultImage,
  title,
  variations,
  onSelect,
}: {
  defaultImage: string;
  title: string;
  variations: GQLProductVariation[];
  onSelect?: (v: GQLProductVariation) => void;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<number>(-1);

  return (
    <div tabIndex={0} onBlur={() => setOpen(false)} className={`${style.swatch} ${open ? style.swatchOpen : ''}`}>
      <div className={style.swatchHeader} onClick={() => setOpen(!open)}>
        <SwatchItem defaultImage={defaultImage} variation={variations[selected]} title={title} />
        <ChevronDownIcon width={20} />
      </div>
      {open && (
        <div className={style.swatchContent}>
          <div className={style.swatchSeparator} />
          {variations?.map((v, i) => (
            <SwatchItem
              key={i}
              title={title}
              defaultImage={defaultImage}
              variation={v}
              selected={selected === i}
              onClick={() => {
                setSelected(i);
                onSelect?.(v);
                setOpen(false);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
