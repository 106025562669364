import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GQLTag } from 'Types';
import { SearchTagsQuery, GetTagsQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import style from './tags.module.scss';
import Tag from './tag';
import { toast } from 'react-toastify';

export default function Tags() {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'createdAt' | 'name'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState<number>(1);

  const isSearch = search !== '';

  const { data, loading, error, fetchMore, previousData } = useQuery(isSearch ? SearchTagsQuery : GetTagsQuery, {
    variables: {
      input: {
        page: 1,
        per_page: 50,
        semantic: isSearch ? false : undefined,
        query_by: isSearch ? ['name', 'slug'] : undefined,
        sort_by: sortBy,
        sort_dir: sortDirection,
        q: search !== '' ? search : undefined,
      },
    },
  });

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page: 50,
            semantic: isSearch ? false : undefined,
            query_by: isSearch ? ['title', 'description'] : undefined,
            sort_by: sortBy,
            sort_dir: sortDirection,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            products: [...prev.tags, ...fetchMoreResult.tags],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, isSearch, search, sortBy, sortDirection],
  );

  const hasMore = page === 1 || (data?.tags && data?.tags.length >= 50) || previousData?.tags.length === 50;
  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.tags.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title="Categorie"
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'name'}
              onClick={() => {
                setSortBy('name');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Titolo
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.tags.map((tag: GQLTag, i: number) => (
            <Tag key={i} tag={tag} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
