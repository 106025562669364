import { gql } from 'GQL';

export const CreateTaskMutation = gql(`
  mutation CreateTask($barcode: String!, $count: Float) {
    task: createTask(barcode: $barcode, count: $count) {
      ...TaskFragment
    }
  }
`);

// export const DeleteTaskMutation = gql(`
//   mutation DeleteTask($id: Float!) {
//     deleteTask(id: $id)
//   }
// `);

// export const UpdateTaskMutation = gql(`
//   mutation UpdateTask(id: Float!, $input: TaskUpdateInput!) {
//     updateTask(input: $input) {
//       ...TaskFragment
//     }
//   }
// `);
