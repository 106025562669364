import { Route, Routes, Navigate } from 'react-router-dom';
import Scanner from './Scanner/scanner';

export function SettingsContent() {
  return (
    <Routes>
      <Route path="/scanner" element={<Scanner />} />
      <Route path="*" element={<Navigate to="/settings/scanner" />} />
    </Routes>
  );
}
