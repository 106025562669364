import { gql } from 'GQL';

export const ProductFragment = gql(`
  fragment ProductFragment on Product {
    id
    createdAt
    updatedAt
    synchedAt
    description
    inStock
    originalId
    price
    sku
    status
    stockCount
    title
    type
    imageURLs
    attributeValues {
      ...AttributeValueFragment
    }
    categories {
      ...CategoryFragment
    }
    variations {
      ...ProductVariationFragment
    }
    tags {
      ...TagFragment
    }
  }
`);
