import style from './sidebar.module.scss';
import { Bars4Icon } from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo-etruria-orizzontale.svg';
import { useEffect, useState } from 'react';

export function SidebarMenu({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  return <div className={style.sidebarMenu}>{children}</div>;
}

export function SidebarMenuItem({
  children,
  icon,
  to,
  onClick,
  selected,
}: {
  children: string;
  icon: JSX.Element;
  to: string;
  onClick?: () => void;
  selected?: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const onClickCb = onClick || (() => navigate(to || '/'));
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        `${style.sidebarMenuItem} ${isActive ? style.sidebarMenuItemSelected : ''}`
      }
      onClick={onClickCb}
    >
      <div className={style.sidebarMenuItemIcon}>{icon}</div>
      <div className={style.sidebarMenuItemText}>{children}</div>
    </NavLink>
  );
}

export function Sidebar({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [sideBarOpen, setSideBarOpen] = useState(localStorage.getItem('sideBarOpen') === 'true' || false);

  useEffect(() => {
    localStorage.setItem('sideBarOpen', sideBarOpen.toString());
  }, [sideBarOpen]);

  return (
    <div className={`${style.sidebar} ${!sideBarOpen ? style.sidebarClosed : ''}`}>
      <div className={style.sidebarHeader}>
        <div className={style.sidebarHeaderLogo}>
          <Logo />
        </div>
        <div className={style.sidebarHeaderIcon} onClick={() => setSideBarOpen(!sideBarOpen)}>
          <Bars4Icon width={24} />
        </div>
      </div>

      {children}
    </div>
  );
}
