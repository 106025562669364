import style from './scanner.module.scss';
import { ContentHeader } from 'Components/ContentHeader';
import { AppContext } from 'Components/AppShell/context';
import { useContext } from 'react';

export default function Scanner() {
  const { requestUserAction, ports } = useContext(AppContext);
  return (
    <div className={style.content}>
      <ContentHeader title="Impostazioni barcode scanner" isSearchVisible={false}>
        {/* <ContentHeaderGroup label="Barcode Scanner">
          <ContentHeaderButton selected={ports && ports.length > 0} onClick={() => requestUserAction()}>
            {ports && ports.length > 0 ? 'Connesso' : 'Connetti'}
          </ContentHeaderButton>
        </ContentHeaderGroup> */}
      </ContentHeader>
      <div className={style.contentBody}>
        {/* lista impostazioni per il barcode scanner */}
        <div className={style.contentBodySetting}>
          <div className={style.contentBodySettingLabel}>Barcode Scanner</div>
          <div className={style.contentBodySettingValue}>
            <button onClick={() => requestUserAction()}>{ports && ports.length > 0 ? 'Connesso' : 'Connetti'}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
