import { gql } from 'GQL';

export const SingleTagFragment = gql(`
  fragment SingleCategoryFragment on Category {
    id
    createdAt
    updatedAt
    synchedAt
    name
    slug
    originalId
  }
`);

export const CategoryFragment = gql(`
  fragment CategoryFragment on Category {
    ...SingleCategoryFragment
    parent {
      ...SingleCategoryFragment
      parent {
        ...SingleCategoryFragment
        parent {
          ...SingleCategoryFragment
        }
      }
    }
  }
`);
