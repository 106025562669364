import { gql } from 'GQL';

export const GetTagsQuery = gql(`
  query GetTagsQuery($input: GraphQLListInput!) {
    tags: tags(input: $input) {
      ...TagFragment
    }
  }
`);

export const SearchTagsQuery = gql(`
  query SearchTags($input: GraphQLSearchInput!) {
    tags: searchTags(input: $input) {
      ...TagFragment
    }
  }
`);
