import { gql } from 'GQL';

export const GetProductsQuery = gql(`
  query GetProducts($input: GraphQLListInput!) {
    products: products(input: $input) {
      ...ProductFragment
    }
  }
`);

export const SearchProductsQuery = gql(`
  query SearchProducts($input: GraphQLSearchInput!) {
    products: searchProducts(input: $input) {
      ...ProductFragment
    }
  }
`);
