import { gql } from 'GQL';

export const GetAttributesQuery = gql(`
  query GetAttributes($input: GraphQLListInput!) {
    attributes: attributes(input: $input) {
      ...AttributeFragment
    }
  }
`);

export const SearchAttributesQuery = gql(`
  query SearchAttributes($input: GraphQLSearchInput!) {
    attributes: searchAttributes(input: $input) {
      ...AttributeFragment
    }
  }
`);
