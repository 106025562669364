import { gql } from 'GQL';

export const ProductVariationFragment = gql(`
  fragment ProductVariationFragment on ProductVariation {
    id
    createdAt
    updatedAt
    synchedAt
    description
    inStock
    originalId
    price
    sku
    stockCount
    title
    imageURLs
    attributeValues {
      ...AttributeValueFragment
    }
  }
`);

export const ProductVariationFragmentWithParent = gql(`
  fragment ProductVariationFragmentWithParent on ProductVariation {
    id
    createdAt
    updatedAt
    synchedAt
    description
    inStock
    originalId
    price
    sku
    stockCount
    title
    imageURLs
    attributeValues {
      ...AttributeValueFragment
    }
    parent {
      ...ProductFragment
    }
  }
`);
