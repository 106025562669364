import { gql } from 'GQL';

export const TagFragment = gql(`
  fragment TagFragment on Tag {
    createdAt
    updatedAt
    synchedAt
    id
    name
    slug
    originalId
  }
`);
