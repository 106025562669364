import style from './toolbar.module.scss';

export function ToolBarItem({
  children,
  label,
  className,
}: {
  children: JSX.Element;
  label: string;
  className?: string;
}) {
  return (
    <div className={`${style.toolbarItem} ${className ? className : ''}`}>
      {children}
      <span className={style.toolbarItemLabel}>{label} </span>
    </div>
  );
}

export function ToolBar({ children }: { children: JSX.Element | JSX.Element[] }) {
  return <div className={style.toolbar}>{children}</div>;
}
