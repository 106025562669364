import { createContext, useState } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { getToken, CasdoorSDK as sdk } from 'utils/casdoor';
import { GetMeQuery } from 'GraphqlQueries';
import Loader from 'Components/Loader';

export const AuthContext = createContext({ token: '' });

export default function Protected({ children }: React.PropsWithChildren): JSX.Element | null {
  const token = getToken();
  const [client] = useState(
    new ApolloClient({
      cache: new InMemoryCache(),
      uri: `${process.env.NODE_ENV === 'development' ? 'http://localhost:8002' : ''}/graphql`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );

  const { data, loading, error } = useQuery(GetMeQuery, {
    client,
  });

  if (!token) {
    window.location.href = sdk.getSigninUrl();
    return null;
  }

  if (error) {
    console.error(error);
    window.location.href = sdk.getSigninUrl();
    return null;
  }

  if (!data?.me && !loading) {
    window.location.href = sdk.getSigninUrl();
    return null;
  }

  return (
    <>
      {loading && <Loader />}
      <ApolloProvider client={client}>
        <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>
      </ApolloProvider>
    </>
  );
}
