import style from './searchbar.module.scss';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';

export default function SearchBar({
  searchText,
  onClick,
  onDebounce,
}: {
  searchText?: string;
  onClick?: (search: string) => void;
  onDebounce?: (search: string) => void;
}): JSX.Element {
  const [search, setSearch] = useState<string>(searchText || '');
  useEffect(() => {
    setSearch(searchText || '');
  }, [searchText]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onDebounce) {
        onDebounce(search);
      }
    }, 500);
    return () => clearTimeout(timeout);
  });

  return (
    <div className={style.searchbar}>
      <input
        className={style.searchbarInput}
        type="text"
        placeholder="Cerca..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className={style.searchbarIcon} onClick={() => onClick && onClick(search)}>
        <MagnifyingGlassIcon width={20} />
      </div>
    </div>
  );
}
