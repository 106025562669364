import { gql } from 'GQL';

export const GetInventoriesQuery = gql(`
  query GetInventoriesQuery($input: GraphQLListInput!) {
    inventories: inventories(input: $input) {
      ...InventoryFragment
    }
  }
`);

export const SearchInventoriesQuery = gql(`
  query SearchInventories($input: GraphQLSearchInput!) {
    inventories: searchInventories(input: $input) {
      ...InventoryFragment
    }
  }
`);
