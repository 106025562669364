import { GQLProduct, GQLProductVariation } from 'Types';
import style from './product.module.scss';
import {
  ArrowPathIcon,
  CubeTransparentIcon,
  DocumentDuplicateIcon,
  BoltIcon,
  RectangleGroupIcon,
  TagIcon,
  CurrencyEuroIcon,
  QrCodeIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Tags, Tag } from 'Components/Tag';
import { ToolBar, ToolBarItem } from 'Components/ToolBar';
import { IconSpec } from 'Components/IconSpec';

import sanitizeHtml from 'sanitize-html';
import VariationsSwatch from './VariationsSwatch';
import { useEffect, useState } from 'react';
import defaultProductImage from 'assets/images/default-product.png';

export default function Product({ product }: { product: GQLProduct }) {
  const [viewVariation, setViewVariation] = useState<GQLProductVariation | undefined>(undefined);
  const [syncStatus, setSyncStatus] = useState<string>('Sincronizzato');
  const brand = product.attributeValues.find((attr) => attr.attribute.slug === 'pa_brand');
  const minPrice =
    product.variations?.reduce((min, v) => (v.price < min ? v.price : min), product.price) || product.price;
  const maxPrice =
    product.variations?.reduce((max, v) => (v.price > max ? v.price : max), product.price) || product.price;

  useEffect(() => {
    if (product.originalId) {
      if (!product.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else if (product.createdAt > product.synchedAt) {
        setSyncStatus('Non Sincronizzato');
      } else if (product.updatedAt > product.synchedAt) {
        setSyncStatus('Non sincronizzato');
      } else {
        setSyncStatus('Sincronizzato');
      }
    } else {
      setSyncStatus('Non sincronizzato');
    }
  }, [product]);

  return (
    <div className={style.product}>
      <div className={style.productContent}>
        <ToolBar>
          <ToolBarItem label="edit">
            <PencilSquareIcon width={20} />
          </ToolBarItem>
          <ToolBarItem label="variant">
            <PlusCircleIcon width={20} />
          </ToolBarItem>
          <ToolBarItem label="sync" className={style.validColorText}>
            <ArrowPathIcon width={20} />
          </ToolBarItem>
          <ToolBarItem label="delete" className={style.denyColorText}>
            <TrashIcon width={20} />
          </ToolBarItem>
        </ToolBar>
        <div className={style.productContentImage}>
          <img src={product.imageURLs[0] || defaultProductImage} alt={product.title} />
        </div>
        <div className={style.productContentInfo}>
          <div className={style.productContentInfoHeader}>
            <div className={style.productContentInfoHeaderTitle}>{product.title}</div>
            <div className={style.productContentInfoHeaderCategory}>
              {product.categories.map((c, i) => (
                <span key={i}>{c.name}</span>
              ))}
            </div>
            <div className={style.productContentInfoHeaderPrice}>
              Prezzo: {minPrice === maxPrice ? minPrice : `${minPrice} - ${maxPrice}`} €
            </div>
          </div>
          <div className={style.productContentInfoBody}>
            <div
              className={style.productContentInfoBodyDescription}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(product.description) }}
            />
            <div className={style.productContentInfoBodySpecs}>
              <IconSpec icon={<CubeTransparentIcon width={20} />} label="Tipo" content={product.type} />
              <IconSpec
                icon={<DocumentDuplicateIcon width={20} />}
                label="Numero varianti"
                content={product.variations?.length.toString() || '0'}
              />
              <IconSpec
                icon={<ArrowPathIcon width={20} />}
                label="Stato sincronizzazione"
                content={syncStatus}
                contentStyle={{ color: syncStatus === 'Sincronizzato' ? style.green : style.red }}
              />
              <IconSpec
                icon={<BoltIcon width={20} />}
                label="Stato magazzino"
                content={product.inStock ? 'In Stock' : 'Out of Stock'}
                contentStyle={{ color: product.inStock ? style.green : style.red }}
              />

              <IconSpec
                icon={<BoltIcon width={20} />}
                label="Stato pubblicazione"
                content={product.status}
                contentStyle={{ color: product.status === 'PUBLISH' ? style.green : style.red }}
              />
            </div>
            <div className={style.productContentInfoBodySpecs}>
              <IconSpec
                icon={<RectangleGroupIcon width={20} />}
                label="Attributi"
                content={
                  <div>
                    {
                      <Tags>
                        {[...new Set(product.attributeValues.map((attr) => attr.attribute.name))].map((v, i) => (
                          <Tag key={i}>{v}</Tag>
                        ))}
                      </Tags>
                    }
                  </div>
                }
              />
            </div>
            <div className={style.productContentInfoBodySpecs}>
              <IconSpec
                icon={<TagIcon width={20} />}
                label="Tags"
                content={
                  <div>
                    {
                      <Tags>
                        {[...new Set(product.tags.map((attr) => attr.name))].map((v, i) => (
                          <Tag key={i}>{v}</Tag>
                        ))}
                      </Tags>
                    }
                  </div>
                }
              />
            </div>
            {product.variations && product.variations.length > 0 && (
              <VariationsSwatch
                defaultImage={product.imageURLs[0] || defaultProductImage}
                title={product.title}
                variations={product.variations as GQLProductVariation[]}
                onSelect={(v) => setViewVariation(v)}
              />
            )}
          </div>
        </div>
        <div className={style.productContentBrand}>
          <img src={brand?.imageURLs[0]} alt={brand?.value} />
        </div>
      </div>
      {viewVariation && <Variation variation={viewVariation} />}
    </div>
  );
}

function AttributeInput({ label, value }: { label: string; value: string }): JSX.Element {
  return <div className={style.attributeInput}>
    <span className={style.attributeInputLabel}>{label}</span>
    <div className={style.attributeInputValue}>{value}</div>
  </div>
}

function Variation({ variation }: { variation: GQLProductVariation }): JSX.Element {
  return (
    <div className={style.variation}>
      <ToolBar>
        <ToolBarItem label="edit">
          <PencilSquareIcon width={20} />
        </ToolBarItem>
        <ToolBarItem label="delete" className={style.denyColorText}>
          <TrashIcon width={20} />
        </ToolBarItem>
      </ToolBar>
      <div className={style.variationImage}>
        <img src={variation.imageURLs[0] || defaultProductImage} alt={variation.title} />
      </div>
      <div className={style.variationInfo}>
        <div className={`${style.variationInfoDescription} ${variation.description === '' ? style.empty : ''}`}>
          {variation.description === '' ? 'Nessuna descrizione' : variation.description}
        </div>
        <div className={style.variationInfoSpecs}>
          <IconSpec icon={<CurrencyEuroIcon width={20} />} label="Prezzo" content={`${variation.price} €`} />
          <IconSpec
            icon={<DocumentDuplicateIcon width={20} />}
            label="In magazzino"
            content={variation.stockCount.toString()}
          />
          <IconSpec icon={<QrCodeIcon width={20} />} label="Codice a barre" content={variation.sku} />
        </div>
        <div className={style.variationInfoAttributes}>
          {variation.attributeValues.map((a, i) => (
            <AttributeInput label={a.attribute.name} value={a.value} />
          ))}
        </div>
      </div>
    </div>
  );
}
