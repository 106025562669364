import {
  QrCodeIcon,
  TrashIcon,
  CubeTransparentIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { IconSpec } from 'Components/IconSpec';
import style from './inventory.module.scss';
import { useMutation } from '@apollo/client';
import { CreateTaskMutation } from 'GraphqlQueries';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GQLInventory } from 'Types';
import { ProductType } from 'GQL/graphql';
import ToastPrompt from 'Components/ToastPrompt';

export default function InventoryRow({
  inventory: { barcode, count, product },
  deleteRowPrompt,
  deleteRow,
  editCount,
}: {
  inventory: GQLInventory;
  deleteRowPrompt: () => void;
  deleteRow: () => void;
  editCount: (count: number) => void;
}) {
  const [productTitle, setProductTitle] = useState<JSX.Element>(<>Prodotto non trovato</>);

  const [createTask] = useMutation(CreateTaskMutation, {
    variables: {
      barcode,
      count,
    },
  });

  useEffect(() => {
    let title = <>Prodotto non trovato</>;

    if (product && product.type === ProductType.Simple) {
      setProductTitle(<b>{product.title}</b>);
      return;
    }

    if (product && product.type === ProductType.Variable && product.variations) {
      title = <>{product.title}</>;

      for (const variation of product.variations) {
        if (variation.sku === barcode) {
          let attrs = [];
          for (const attr of variation.attributeValues) {
            if (attr.attribute.slug !== 'pa_brand') {
              attrs.push(
                <span className={style.variationAttributesRow} key={attrs.length}>
                  <b>{attr.attribute.name}:</b> <span>{attr.value}</span>
                </span>,
              );
            }
          }

          title = (
            <div className={style.variationTitle}>
              {product.title}
              <div className={style.variationAttributes}>{attrs}</div>
            </div>
          );

          break;
        }
      }
    }

    setProductTitle(title);
  }, [product, barcode]);

  const addTask = async () => {
    const toastId = toast(
      <ToastPrompt
        actionLabel="Crea"
        textLabel={`Sicuro di voler creare un task per ${barcode}?`}
        onApply={function (): void {
          createTask().then(() => {
            deleteRow();
          });
          toast.dismiss(toastId);
        }}
        onAbort={function (): void {
          toast.dismiss(toastId);
        }}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  return (
    <div className={style.inventoryRow}>
      <IconSpec icon={<QrCodeIcon width={20} />} label="Prodotto" content={productTitle} />
      {(product && <IconSpec icon={<CubeTransparentIcon width={20} />} label="Tipo" content={product.type} />) || (
        <div></div>
      )}
      <IconSpec icon={<QrCodeIcon width={20} />} label="Barcode" content={barcode} />
      <IconSpec
        icon={<DocumentDuplicateIcon width={20} />}
        label="In magazzino"
        content={count.toString()}
        setData={(d) => {
          editCount(parseInt(d) - count);
        }}
        editableType="number"
        editable
      />
      <div className={style.inventoryRowButtons}>
        <div className={style.inventoryRowButtonsGroup}>
          <IconSpec
            icon={<PlusCircleIcon width={20} color={style.green} />}
            label=""
            labelStyle={{ color: style.green }}
            onClick={() => editCount(1)}
          />
          <IconSpec
            icon={<MinusCircleIcon width={20} color={style.green} />}
            label=""
            labelStyle={{ color: style.green }}
            onClick={() => editCount(-1)}
          />
        </div>
        {!product && (
          <IconSpec
            icon={<PlusCircleIcon width={20} color={style.green} />}
            label="Crea Task"
            labelStyle={{ color: style.green }}
            onClick={addTask}
          />
        )}
        <IconSpec
          icon={<TrashIcon width={20} color={style.red} />}
          label="Elimina"
          labelStyle={{ color: style.red }}
          onClick={deleteRowPrompt}
        />
      </div>
    </div>
  );
}
