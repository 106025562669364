import {
  DocumentDuplicateIcon,
  ListBulletIcon,
  QrCodeIcon,
  QueueListIcon,
  RectangleGroupIcon,
  SwatchIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import { Sidebar, SidebarMenu, SidebarMenuItem } from 'Components/Sidebar';

export function WareHouseSidebar(): JSX.Element {
  return (
    <Sidebar>
      <SidebarMenu>
        <SidebarMenuItem icon={<ListBulletIcon width={20} />} to="/warehouse/products">
          Prodotti
        </SidebarMenuItem>
        <SidebarMenuItem icon={<QrCodeIcon width={20} />} to="/warehouse/scan">
          Nuova Scansione
        </SidebarMenuItem>
        <SidebarMenuItem icon={<DocumentDuplicateIcon width={20} />} to="/warehouse/inventory">
          Inventario
        </SidebarMenuItem>
        <SidebarMenuItem icon={<QueueListIcon width={20} />} to="/warehouse/tasks">
          Lavori
        </SidebarMenuItem>
        <SidebarMenuItem icon={<RectangleGroupIcon width={20} />} to="/warehouse/attributes">
          Attributi
        </SidebarMenuItem>
        <SidebarMenuItem icon={<SwatchIcon width={20} />} to="/warehouse/categories">
          Categorie
        </SidebarMenuItem>
        <SidebarMenuItem icon={<TagIcon width={20} />} to="/warehouse/tags">
          Tag
        </SidebarMenuItem>
      </SidebarMenu>
    </Sidebar>
  );
}
