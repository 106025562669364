import Products from './Product/products';
import { Route, Routes, Navigate } from 'react-router-dom';
import Scanner from './Scanner';
import Tasks from './Task/tasks';
import Attributes from './Attribute/attributes';
import Categories from './Category/categories';
import Tags from './Tag/tags';
import AttributeValues from './Attribute/attributeValues';
import Inventory from './Inventory/inventory';

export function WareHouseContent() {
  return (
    <Routes>
      <Route path="/products" element={<Products />} />
      <Route path="/scan" element={<Scanner />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/attributes" element={<Attributes />} />
      <Route path="/attributeValues/:parentId/:parentName" element={<AttributeValues />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/categories/:parentId/:parentName" element={<Categories />} />
      <Route path="/tags" element={<Tags />} />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="*" element={<Navigate to="/warehouse/products" />} />
    </Routes>
  );
}
