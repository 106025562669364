import { GQLTask } from 'Types';
import style from './task.module.scss';
import { IconSpec } from 'Components/IconSpec';
import { useEffect, useState } from 'react';
import {
  BoltIcon,
  QrCodeIcon,
  CheckCircleIcon,
  PlayPauseIcon,
  PlayCircleIcon,
  XCircleIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DeleteTaskMutation } from 'GraphqlQueries';
import { TaskStatus } from 'GQL/graphql';
import TaskResult from './taskResult';
import ToastPrompt from 'Components/ToastPrompt';

export default function Task({ task }: { task: GQLTask }): JSX.Element {
  const { id, barcode, count, createdAt, updatedAt, status } = task;
  const [statusColor, setStatusColor] = useState(style.grey);
  const [statusIcon, setStatusIcon] = useState(<BoltIcon width={20} />);
  const [statusText, setStatusText] = useState('');
  const [detailOpen, setDetailOpen] = useState(false);

  const [updateProduct] = useMutation(DeleteTaskMutation, {
    variables: {
      id: parseInt(id),
    },
    update(cache) {
      cache.modify({
        fields: {
          tasks(existingTasks = [], { readField }) {
            return existingTasks.filter((taskRef: any) => id !== readField('id', taskRef));
          },
        },
      });
    },
  });

  useEffect(() => {
    switch (status) {
      case 'IN_PROGRESS':
        setStatusIcon(<BoltIcon width={20} />);
        setStatusColor(style.yellow);
        setStatusText('In corso');
        break;
      case 'USER_WAITING':
        setStatusIcon(<PlayPauseIcon width={20} />);
        setStatusColor(style.yellow);
        setStatusText('In attesa di utente');
        break;
      case 'PENDING':
        setStatusIcon(<PlayCircleIcon width={20} />);
        setStatusColor(style.yellow);
        setStatusText('In attesa');
        break;
      case 'COMPLETED':
        setStatusIcon(<CheckCircleIcon width={20} />);
        setStatusColor(style.green);
        setStatusText('Completato');
        break;
      case 'CANCELLED':
        setStatusIcon(<XCircleIcon width={20} />);
        setStatusColor(style.red);
        setStatusText('Annullato');
        break;
      default:
        setStatusIcon(<BoltIcon width={20} />);
        setStatusColor(style.grey);
        setStatusText('Sconosciuto');
    }
  }, [status, setStatusIcon, setStatusColor]);

  const deleteTask = () => {
    const toastId = toast(
      <ToastPrompt
        onAbort={() => {
          toast.dismiss(toastId);
        }}
        onApply={() => {
          updateProduct();
          toast.dismiss(toastId);
        }}
        actionLabel="Elimina"
        textLabel={`Eliminare il lavoro ${id}?`}
      />,
      {
        style: {
          backgroundColor: 'white',
        },
      },
    );
  };

  return (
    <div className={style.task}>
      <div className={style.taskBody}>
        <div className={style.taskBodyId}>{id}</div>
        <div className={style.taskBodyContent}>
          <IconSpec icon={statusIcon} label="Stato" content={statusText} contentStyle={{ color: statusColor }} />
          <IconSpec icon={<QrCodeIcon width={20} />} label="Barcode" content={barcode.toString()} />
          <IconSpec icon={<BoltIcon width={20} />} label="Scansioni" content={count.toString()} />
          <IconSpec icon={<BoltIcon width={20} />} label="Creato" content={new Date(createdAt).toLocaleString()} />
          <IconSpec icon={<BoltIcon width={20} />} label="Aggiornato" content={new Date(updatedAt).toLocaleString()} />
          {(status === TaskStatus.UserWaiting && (
            <IconSpec
              icon={
                detailOpen ? (
                  <ChevronUpIcon width={20} color={style.green} />
                ) : (
                  <ChevronDownIcon width={20} color={style.green} />
                )
              }
              label={detailOpen ? 'Nascondi dettagli' : 'Mostra dettagli'}
              labelStyle={{ color: style.green }}
              onClick={() => setDetailOpen(!detailOpen)}
            />
          )) || <div></div>}
          <IconSpec
            icon={<TrashIcon width={20} color={style.red} />}
            label="Elimina"
            labelStyle={{ color: style.red }}
            onClick={deleteTask}
          />
        </div>
      </div>
      {detailOpen && (
        <div className={style.taskDetail}>
          <TaskResult id={id} />
        </div>
      )}
    </div>
  );
}
