import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { ContentHeader, ContentHeaderGroup, ContentHeaderButton } from 'Components/ContentHeader';
import Loader from 'Components/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GQLCategory } from 'Types';
import { SearchCategoriesQuery } from 'GraphqlQueries';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import style from './categories.module.scss';
import Category from './category';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export default function Categories() {
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<'createdAt' | 'name'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [page, setPage] = useState<number>(1);
  const { parentId, parentName } = useParams();

  const { data, loading, error, fetchMore, previousData } = useQuery(SearchCategoriesQuery, {
    variables: {
      input: {
        page: 1,
        per_page: 50,
        semantic: false,
        query_by: ['name', 'slug'],
        sort_by: sortBy,
        sort_dir: sortDirection,
        filter_by: `parent:${parentId || 0}`,
        q: search !== '' ? search : undefined,
      },
    },
  });

  useEffect(() => {
    if (error) {
      toast.error('Errore durante il caricamento dei prodotti: ' + error.message);
    }
  }, [error]);

  const nextPage = useCallback(
    (page: number) => {
      fetchMore({
        variables: {
          input: {
            page,
            per_page: 50,
            semantic: false,
            query_by: ['name', 'slug'],
            sort_by: sortBy,
            sort_dir: sortDirection,
            filter_by: `parent:${parentId || 0}`,
            q: search !== '' ? search : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            products: [...prev.categories, ...fetchMoreResult.categories],
          };
        },
      });

      setPage(page);
    },
    [fetchMore, parentId, search, sortBy, sortDirection],
  );

  const hasMore =
    page === 1 || (data?.categories && data?.categories.length >= 50) || previousData?.categories.length === 50;
  return (
    <div className={style.content} id="scrollableContent">
      <InfiniteScroll
        dataLength={data?.categories.length || 0}
        next={() => nextPage(page + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableContent"
        style={{ minHeight: '100vh' }}
      >
        {loading && <Loader />}
        <ContentHeader
          title={parentName ? `Categorie > ${parentName}` : 'Categorie'}
          isSearchVisible
          searchText={search}
          onClickSearch={(s) => setSearch(s)}
          onDebounceSearch={(s) => setSearch(s)}
        >
          <ContentHeaderGroup label="Ordina per:">
            <ContentHeaderButton
              selected={sortBy === 'createdAt'}
              onClick={() => {
                setSortBy('createdAt');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Data creazione
            </ContentHeaderButton>
            <ContentHeaderButton
              selected={sortBy === 'name'}
              onClick={() => {
                setSortBy('name');
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
              }}
              icon={sortDirection === 'asc' ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
            >
              Titolo
            </ContentHeaderButton>
          </ContentHeaderGroup>
        </ContentHeader>

        <div className={style.contentBody}>
          {data?.categories.map((attribute: GQLCategory, i) => (
            <Category key={i} category={attribute} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
