import { gql } from 'GQL';

export const AttributeValueFragment = gql(`
  fragment AttributeValueFragment on AttributeValue {
    createdAt
    updatedAt
    synchedAt
    slug
    value
    imageURLs
    originalId
    attribute {
      ...AttributeFragment
    }
  }
`);
