import { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import style from './iconspec.module.scss';

export function IconSpec<V extends string | JSX.Element>({
  icon,
  iconStyle,
  label,
  labelStyle,
  content,
  contentStyle,
  onClick,
  inputStyle,
  editable,
  editableType,
  setData,
}: {
  icon: JSX.Element;
  iconStyle?: React.CSSProperties;
  label: string;
  labelStyle?: React.CSSProperties;
  content?: V;
  contentStyle?: React.CSSProperties;
  onClick?: () => void;
  inputStyle?: React.CSSProperties;
  editable?: boolean;
  editableType?: HTMLInputTypeAttribute;
  setData?: (data: V) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(content);

  useEffect(() => {
    setValue(content);
  }, [content]);

  useEffect(() => {
    if (editing && value && value !== content) {
      setData && setData(value);
    }
  }, [value, content, setData, editing]);

  return (
    <div className={style.iconSpec} style={onClick ? { cursor: 'pointer', userSelect: 'none' } : {}} onClick={onClick}>
      <div className={style.iconSpecIcon} style={iconStyle}>
        {icon}
      </div>
      <div className={style.iconSpecContent}>
        <span className={style.iconSpecContentLabel} style={labelStyle}>
          {label}
        </span>
        {content && (
          <span
            className={style.iconSpecContentValue}
            style={contentStyle}
            onClick={() => {
              if (editable) {
                setEditing(true);
              }
            }}
          >
            {!editing ? (
              content
            ) : typeof value === 'string' ? (
              <input
                autoFocus
                value={value}
                onChange={(e) => setValue(e.target.value as V)}
                type={editableType || 'text'}
                className={style.iconSpecContentValueInput}
                onBlur={() => setEditing(false)}
                onKeyDown={(e) => e.key === 'Enter' && setEditing(false)}
                style={inputStyle}
              />
            ) : (
              value
            )}
          </span>
        )}
      </div>
    </div>
  );
}
